<script>
  /**
   * A radio button with a label, which can optionally be hidden.
   */
  export default {
    inject: {
      group: 'BaseRadioGroup',
    },
    props: {
      /** The value to use when the radio button is checked. */
      value: {
        type: [String, Number],
        required: true,
      },
      /** Whether the radio button is disabled. A disabled radio button is grayed out and cannot be focused or checked. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** The size of the button. */
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
      /** The background color of the button. */
      color: {
        type: String,
        default: 'primary',
        validator: (value) => ['primary', 'secondary', 'green', 'gray', 'black'].includes(value),
      },
      /** The vertical alignment of the button relative to the label. */
      verticalAlignment: {
        type: String,
        default: 'top',
        validator: (value) => ['top', 'center'].includes(value),
      },
      /** Whether the button is hidden **/
      buttonHidden: {
        type: Boolean,
        default: false,
      },
      /** Whether the label is hidden, in which case only the circular button is shown. Even when hidden, the label remains accessible to screen readers. */
      labelHidden: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      verticalAlignmentClass() {
        switch (this.verticalAlignment) {
          case 'top':
            return 'items-start'
          case 'center':
            return 'items-center'
          default:
            return ''
        }
      },
      checked() {
        return Boolean(this.value && this.value === this.group.value)
      },
      listeners() {
        const { change, ...listeners } = this.$listeners
        return listeners
      },
      dotClasses() {
        // for purgecss: bg-primary bg-secondary bg-green bg-gray bg-black
        return [
          'radio-button-dot',
          this.size === 'sm' ? 'w-2 h-2' : 'w-3 h-3',
          'rounded-full',
          'opacity-0',
          this.color ? `bg-${this.color}` : '',
        ].join(' ')
      },
      buttonSpaceClasses() {
        if (!this.buttonHidden) {
          return this.size === 'sm' ? 'w-4 h-4' : 'w-6 h-6'
        }
        return ''
      },
      labelSpaceClasses() {
        if (!this.buttonHidden) {
          return this.size === 'sm' ? 'ml-2' : 'ml-3'
        }
        return ''
      },
    },
    methods: {
      change() {
        /** Emitted *on the parent group* when this radio button is checked. */
        this.group.$emit('change', this.value)
      },
    },
  }
</script>

<template>
  <label
    :class="`flex ${verticalAlignmentClass} ${
      disabled ? 'text-gray-light' : 'cursor-pointer'
    } font-normal leading-tight`"
  >
    <span class="flex items-center flex-shrink-0">
      <!-- Zero-width space character, used to align the button properly -->
      <span v-if="!buttonHidden">&#8203;</span>
      <span :class="buttonSpaceClasses">
        <input
          type="radio"
          :name="group.inputName"
          :value="value"
          :checked="checked"
          :disabled="disabled"
          class="radio-input absolute left-0 top-0 opacity-0"
          v-on="listeners"
          @change="change"
        />
        <!-- @slot Optional. The circular button, if you want to override it. -->
        <slot name="button">
          <span
            v-if="!buttonHidden"
            class="radio-button flex justify-center items-center w-full h-full rounded-full border transform transition duration-200"
          >
            <span :class="dotClasses" />
          </span>
        </slot>
      </span>
    </span>
    <span
      v-if="!labelHidden"
      :class="`radio-label ${labelSpaceClasses} block`"
      data-cy="radio-label"
    >
      <span class="block overflow-hidden ellipsis">
        <!-- @slot The radio button’s label. Should contain text. -->
        <slot />
      </span>
    </span>
    <span v-else class="sr-only">
      <slot />
    </span>
  </label>
</template>

<style lang="postcss" scoped>
  .radio-button {
    @apply border-gray-active bg-transparent;

    .radio-input:not(:disabled):focus ~ & {
      @apply border-black border-opacity-50 shadow-outline-black-50;
    }

    .radio-input:not(:disabled):hover ~ & {
      @apply border-checkbox-darker;
    }

    .radio-input:not(:disabled):active ~ & {
      @apply border-checkbox-dark scale-90 transition-none;
    }

    .radio-input:not(:disabled):checked ~ & {
      .radio-button-dot {
        @apply opacity-100;
      }
    }

    .radio-input:disabled ~ & {
      @apply border-transparent bg-gray-lighter;
    }

    .radio-input:disabled:checked ~ & {
      .radio-button-dot {
        @apply opacity-100 bg-gray-light;
      }
    }
  }
</style>
